import appType from './ApiConfig';
import {
  DOMAIN_PIVOTEL, INTELSAT, PRIMARY_COLOR_BUTTONS, PRIMARY_COLOR_BUTTONS_HOVER, PRIMARY_COLOR_BUTTONS_INTELSAT, PRIMARY_COLOR_BUTTONS_PIVOTEL, PRIMARY_COLOR_BUTTONS_PIVOTEL_HOVER,
} from './Constants';

export const BUTTON_COLOR = (appType === DOMAIN_PIVOTEL ? PRIMARY_COLOR_BUTTONS_PIVOTEL : appType === INTELSAT ? PRIMARY_COLOR_BUTTONS_INTELSAT : PRIMARY_COLOR_BUTTONS);
export const BUTTON_HOVER_COLOR = (appType === DOMAIN_PIVOTEL ? PRIMARY_COLOR_BUTTONS_PIVOTEL_HOVER : PRIMARY_COLOR_BUTTONS_HOVER);

export const BUTTON_BACKGROUND_COLOR_STYLE = { backgroundColor: BUTTON_COLOR };

export const ACTIVE_BOX_BG_COLOR = "#376AFA"
export const NAV_BOX_BORDER_COLOR = "#D6D8E5"
export const NAV_BOX_TEXT_COLOR = "#404585"
export const BUTTON_COLOR_NEW_UI = "#376AFA"
