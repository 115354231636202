import React, { Component } from "react";
import HomeCarousel from "./HomeCarousel";
import { getAuthData } from "../../Services/StorageService";
import CookiePolicy from "../App/CookiePolicy";
import {ONSHIP_PROD_URL, ONSHIP_LANDING, VIKAND_PROD_URL, VIKAND_HEALTH_PROD_URL, THURAYA_T2M_LANDING, THURAYA_T2M_PROD_URL, VIKAND_DIRECT_LANDING, STATION_SATCOM_LANDING, STATIONSATCOM_PROD_URL,INTELSAT_STAGE_URL, INTELSAT_PROD_URL, ONECARE_PROD_URL, ONECARE_LANDING, SIGMA_LANDING, SIGMA_PROD_URL, ONECARE_PROD_URL_NEW, VIKAND_CONNECT_PROD_URL, PIVOTEL_PROD_URL, PIVOTEL_LANDING, THURAYA_LANDING, STAGE_8_THURAYA_URL, ONSHIP_NEW_STAGE2_URL, VIKAND_NEW_STAGE_URL, STATION_ONE_URL, STATION_ONE_LANDING, PIVOTEL_TITLE, ONECARE_PROD_URL_NEW_V2, WITHOUT_CAROUSEL, INTELSAT_LANDING,STATION_ONE_STAGE_URL, INTELSAT_DEV_URL} from '../../Utils/Constants';
import history from "../../Services/History";
import SignupView from "../Signup/SignupView";

class Home extends Component {
	componentDidMount() {

		let { hydrateUserData, user } = this.props;
		if (!user) {
			let storedUser = getAuthData();
			if (storedUser) {
				hydrateUserData(storedUser);
				this.props.history.push("/app/home");
			}
			if (
				[
					VIKAND_PROD_URL,
					VIKAND_HEALTH_PROD_URL,
					VIKAND_CONNECT_PROD_URL,
					"stage2.frontm.com",
					VIKAND_NEW_STAGE_URL
				].includes(window.location.hostname) &&
				window.location.pathname !== VIKAND_DIRECT_LANDING
			) {
				window.location.href = `${window.location.origin}${VIKAND_DIRECT_LANDING}`;
			} else if (
				[THURAYA_T2M_PROD_URL, "stage3.frontm.com"].includes(
					window.location.hostname
				)
			) {
				window.location.href = `${window.location.origin}${THURAYA_T2M_LANDING}`;
			} else if ([ONSHIP_PROD_URL, ONSHIP_NEW_STAGE2_URL].includes(window.location.hostname)) {
				window.location.href = `${window.location.origin}${ONSHIP_LANDING}`;
			} else if (
				["stage6.frontm.com", STATIONSATCOM_PROD_URL].includes(
					window.location.hostname
				)
			) {
				window.location.href = `${window.location.origin}${STATION_SATCOM_LANDING}`;
			}
			else if (
				[INTELSAT_DEV_URL, INTELSAT_STAGE_URL, INTELSAT_PROD_URL].includes(
					window.location.hostname
				)
			) {
				window.location.href = `${window.location.origin}${INTELSAT_LANDING}`;
			}
			else if ([SIGMA_PROD_URL].includes(window.location.hostname)) {
				window.location.href = `${window.location.origin}${SIGMA_LANDING}`;
			}
			// else if ([STAGE_8_THURAYA_URL].includes(window.location.hostname)) {
			// 	window.location.href = `${window.location.origin}${THURAYA_LANDING}`;
			// }
			else if ([PIVOTEL_PROD_URL, "stage7.frontm.com"].includes(window.location.hostname)) {
				window.document.title = PIVOTEL_TITLE;
				history.push(`${PIVOTEL_LANDING}`)
			}
			else if (
				["stage5.frontm.com",ONECARE_PROD_URL_NEW_V2, ONECARE_PROD_URL, ONECARE_PROD_URL_NEW].includes(
					window.location.hostname
				)
			) {
				window.location.href = `${window.location.origin}${ONECARE_LANDING}`;
			}
			else if (
				[STATION_ONE_URL, STATION_ONE_STAGE_URL].includes(
					window.location.hostname
				)
			) {
				window.location.href = `${window.location.origin}${STATION_ONE_LANDING}`;
			}
		} else {
			if (
				[
					VIKAND_PROD_URL,
					VIKAND_CONNECT_PROD_URL,
					VIKAND_HEALTH_PROD_URL,
					"stage2.frontm.com",
					VIKAND_NEW_STAGE_URL
				].includes(window.location.hostname) &&
				window.location.pathname !== VIKAND_DIRECT_LANDING
			) {
				window.location.href = `${window.location.origin}${VIKAND_DIRECT_LANDING}`;
			} else if (
				[THURAYA_T2M_PROD_URL, "stage3.frontm.com"].includes(
					window.location.hostname
				)
			) {
				window.location.href = `${window.location.origin}${THURAYA_T2M_LANDING}`;
			} else if ([ONSHIP_PROD_URL, ONSHIP_NEW_STAGE2_URL].includes(window.location.hostname)) {
				window.location.href = `${window.location.origin}${ONSHIP_LANDING}`;
			}
			else if ([SIGMA_PROD_URL].includes(window.location.hostname)) {
				window.location.href = `${window.location.origin}${SIGMA_LANDING}`;
			}
			else if ([PIVOTEL_PROD_URL, "stage7.frontm.com"].includes(window.location.hostname)) {
				window.location.href = `${window.location.origin}${PIVOTEL_LANDING}`;
			}
			// else if ([STAGE_8_THURAYA_URL].includes(window.location.hostname)) {
			// 	window.location.href = `${window.location.origin}${THURAYA_LANDING}`;
			// }
			else if (
				["stage6.frontm.com", STATIONSATCOM_PROD_URL].includes(
					window.location.hostname
				)
			) {
				window.location.href = `${window.location.origin}${STATION_SATCOM_LANDING}`;
			}
			else if (
				[INTELSAT_STAGE_URL, INTELSAT_PROD_URL].includes(
					window.location.hostname
				)
			) {
				window.location.href = `${window.location.origin}${INTELSAT_LANDING}`;
			} 
			else if (
				[ONECARE_PROD_URL, ONECARE_PROD_URL_NEW].includes(
					window.location.hostname
				)
			) {
				window.location.href = `${window.location.origin}${ONECARE_LANDING}`;
			}
			else if (
				[STATION_ONE_URL, STATION_ONE_STAGE_URL].includes(
					window.location.hostname
				)
			) {
				window.location.href = `${window.location.origin}${STATION_ONE_LANDING}`;
			}
		}
	}
	returnToMain = () => {
		if(WITHOUT_CAROUSEL.includes(window.location.hostname)){
			return <SignupView />
		} else {
			return <HomeCarousel history={this.props.history} />
		}
	};
	render() {
		return (
			<React.Fragment>
				{this.returnToMain()}
				
				{!this.props.cookiePolicyAccepted && (
					<div
						style={{
							position: "fixed",
							bottom: 0,
							width: "100%",
						}}
					>
						<CookiePolicy acceptCookiePolicy={this.props.acceptCookiePolicy} />
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default Home;
