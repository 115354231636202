import commonConfig from "./common.config";

export default {
  ...commonConfig,
  onecarePushkey: 'BM3MxIRu28xJIcIs76BlsH7cPM17pBZwMMAcvq1gQMGgaDnA3PzZ5vgndhNtoFQPIon__LFTQqRF80Z6AHNjGzs',
  vikandDirectPushkey: 'BCC9TlmesdLmBIYUUYhhu8Df0angc2g6tf8zY9BlUnvQavMYrrAp9ZnsKz_UzQ-aC1_EOEtg4JQMqjNPuKc-t-g',
  onshipPushkey: 'BPpdtKdM-aTcVL4i2Z5ccHeOnLJbZaozItAv2UR0_C08r_kLVswJVo5W1HYat3Kl58PLtRgwhSEcEB8Fubv2T2M',
  stationonePushKey: 'BMtc1kZ8zIV8TQ0zjquiuC4iTUTFy-CntmB-OxH64jW_oolKEItsk-y87B0dFBXDv0ViXW3hKL07uOyn5VzCxbg',
  vikandTestPushkey: 'BB-4KG_6eHYLLLnaamkGXntHCLuPUC1jh5Iz2CRIJtBVN1T9StH89ZgKr7bUnR3BrFrsGVDWpESOWdFbk-yeSFk',
  onshipWebPushAppType: "onship",
  vikandWebPushAppType: "vikand",
  onecareWebPushAppType: "onecare",
  stationoneWebPushAppType: "stationone_prod",
  vikandTestWebPushAppType: "vikand_test",
   dailyPlatformUrl: 'https://frontm.daily.co/',
  dailyRedirectUrl:'https://dailydev.frontm.ai/'
};
