/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import './Main.css';
import store from '../../State/configureStore';
import ReactGA from 'react-ga';
import Loadable from 'react-loadable';
import { Provider } from 'react-redux';
import { Route, Switch, Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import ErrorPageContainer from '../../Containers/ErrorPageContainer';
import HomeContainer from '../../Containers/HomeContainer';
import history from '../../Services/History';
import { setSignupPath } from '../../Services/StorageService';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import {VIKAND_PROD_URL, VIKAND_HEALTH_PROD_URL, THURAYA_T2M_PROD_URL, ONSHIP_PROD_URL, STATIONSATCOM_PROD_URL, INTELSAT_PROD_URL, VIKAND_CONNECT_PROD_URL, PIVOTEL_PROD_URL, STAGE_8_THURAYA_URL, ONSHIP_NEW_STAGE2_URL, VIKAND_NEW_STAGE_URL, ONECARE_STAGE_URL, STATION_ONE_URL, INTELSAT_STAGE_URL, PIVOTEL_TITLE, STATION_ONE_STAGE_URL} from '../../Utils/Constants';
import { updateManifestFile } from '../../Utils/Helpers';
import Error404 from '../ErrorPages/Error404';
import NonConversationalLoader from '../NonConversationalLoader';
import ProtectedRoute from '../ProtectedRoute';
import Spinner from '../Spinner';
import UpdateAppVersionDlg from '../UpdateAppVersionDlg';
import CookiedDisableDlg from '../CookiedDisableDlg';

const LoginPage = Loadable({
  loader: () => import('../Home/LoginPage'),
  loading: Spinner,
  delay: 500,
});

const OtpPage = Loadable({
  loader: () => import('../Home/OtpPage'),
  loading: Spinner,
  delay: 500,
});
const QRCodePage = Loadable({
  loader: () => import('../Home/QRCodePage'),
  loading: Spinner,
  delay: 500,
});
const CorporateStatusPage = Loadable({
  loader: () => import('../Home/CorporateStatusPage'),
  loading: Spinner,
  delay: 500,
});

const SignupView = Loadable({
  loader: () => import('../Signup/SignupView'),
  loading: Spinner,
  delay: 500,
});

const OnShipSignInView = Loadable({
  loader: () => import('../Signup/OnShipSignInView'),
  loading: Spinner,
  delay: 500,
});

const SattracklandSigninView = Loadable({
  loader: () => import('../Signup/SattracklandSigninView'),
  loading: Spinner,
  delay: 500,
});

const SendCodeAgain = Loadable({
  loader: () => import('../Signup/SendCodeAgain'),
  loading: Spinner,
  delay: 500,
});

const ResetPassword = Loadable({
  loader: () => import('../ResetPassword/ResetPassword'),
  loading: Spinner,
  delay: 500,
});

const ResetConfirmPassword = Loadable({
  loader: () => import('../ResetPassword/ResetConfirmPassword'),
  loading: Spinner,
  delay: 500,
});

const UserVerificationView = Loadable({
  loader: () => import('../Signup/UserVerificationView'),
  loading: Spinner,
  delay: 500,
});

const LinkableMessage = Loadable({
  loader: () => import('../Links/LinkableMessage'),
  loading: Spinner,
  delay: 500,
});

const App = Loadable({
  loader: () => import('../App/App'),
  loading: Spinner,
  delay: 500,
});

const JitsiCall = Loadable({
  loader: () => import('../Home/JitsiCall'),
  loading: Spinner,
  delay: 500,
});

if (window?.location?.pathname) {
  const pathArray = [
    '/login',
    '/thuraya',
    '/vikand',
    '/vikandconnect',
    '/intelli4desks',
    '/voyagervoice',
    '/stationsatcom',
    '/sigma',
    '/onship',
    '/sattrackland',
    '/pulsar',
    '/stationone',
    '/intelsat',
  ];
  if (window.location.pathname === '/') {
    setSignupPath('/login');
  } else {
    const pathIndex = pathArray.findIndex(
      (elem) => elem === window.location.pathname,
    );
    if (pathIndex !== -1) {
      setSignupPath(window.location.pathname);
    }
  }
}

if (window.location.hostname.search('frontm.ai') !== -1) {
  ReactGA.initialize('UA-37782731-6');
  history.listen((location) => {
    ReactGA.pageview(location.pathname + location.search);
  });
}


const Main = () => {
  const [showToast, setShowToast] = useState(false);
  const [updatedSW, setUpdatedSW] = useState(null);
  const [registrationObj, setRegistrationObj] = useState(null);
  const [isSWUpdateAvailable, setIsSWUpdateAvailable] = useState(false);
  const [wasSWInstalledSuccessfully, setWasSWInstalledSuccessfully] = useState(false);
  // const test = useParams();
  const [isSupported, setIsSupported] = useState(true);

  const resetSWNotificationStates = () => {
    if (updatedSW && isSWUpdateAvailable) {
      updatedSW.postMessage({ type: 'SKIP_WAITING' });
    }

    setUpdatedSW(null);
    setRegistrationObj(null);
    setIsSWUpdateAvailable(false);
    setWasSWInstalledSuccessfully(false);
  };

  const onSWUpdateAvailable = (registration) => {
    if (registration) {
      setUpdatedSW(registration.waiting);
      setRegistrationObj(registration);
      setIsSWUpdateAvailable(true);
      setWasSWInstalledSuccessfully(false);
      localStorage.setItem('shouldCleanStorage', 'true');
    } else {
      console.log('Warning: onSWUpdateAvailable() was called without a Workbox registration object, or, component was not yet mounted.');
    }
  };

  const onSWInstallSuccess = (registration) => {
    setUpdatedSW(registration);
    setRegistrationObj(registration);
    setIsSWUpdateAvailable(false);
    setWasSWInstalledSuccessfully(true);
  };

  useEffect(() => {
    navigator.serviceWorker.onmessage = function (event) {
      let msg = event.data;    // The message data sent by the service worker

   // Check if the data is already an object, if not, parse it
    if (typeof msg === 'string') {
      try {
        msg = JSON.parse(msg);
      } catch (e) {
        console.error("Error parsing JSON:", e);
        return; // Exit early if JSON is invalid
      }
    }
      if (msg?.url) {
        sessionStorage.setItem('notificationData', JSON.stringify(msg?.message));
				const URL = `/messages${msg?.url.split("/messages")[1]}`
        window.history.pushState({}, '', URL);
				window.dispatchEvent(new PopStateEvent('popstate'));
      }

    };
    window.onload = () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(() => {
          setShowToast(true);
        });
      }
    };

    resetSWNotificationStates();

    serviceWorkerRegistration.register({
      onUpdate: onSWUpdateAvailable,
      onSuccess: onSWInstallSuccess,
    });
  }, []);

  useEffect(() => {
    if (
      [
        VIKAND_PROD_URL,
        VIKAND_HEALTH_PROD_URL,
        VIKAND_CONNECT_PROD_URL,
        'stage2.frontm.com',
        VIKAND_NEW_STAGE_URL
      ].includes(window.location.hostname)
    ) {
      updateManifestFile('./vikand-manifest.json');
    } else if (
      [THURAYA_T2M_PROD_URL, 'stage3.frontm.com', STAGE_8_THURAYA_URL].includes(
        window.location.hostname,
      )
    ) {
      updateManifestFile('./thuraya-sattrack-manifest.json');
    } else if ([ONSHIP_PROD_URL, ONSHIP_NEW_STAGE2_URL].includes(window.location.hostname)) {
      updateManifestFile('./onship-manifest.json');
    } else if (
      ['stage6.frontm.com', STATIONSATCOM_PROD_URL].includes(
        window.location.hostname,
      )
    ) {
      updateManifestFile('./stationSatcom-manifest.json');
    } else if (
      [STATION_ONE_URL, STATION_ONE_STAGE_URL].includes(
        window.location.hostname,
      )
    ) {
      updateManifestFile('./stationSatcom-manifest.json');
    }
    else if (
      [ INTELSAT_PROD_URL].includes(
        window.location.hostname,
      )
    ) {
      updateManifestFile('./intelsat-manifest.json');
    } else if (
      [INTELSAT_STAGE_URL].includes(
        window.location.hostname,
      )
    ) {
      updateManifestFile('./intelsat-manifest.json');
    }
     else if (
      ['stage7.frontm.com', PIVOTEL_PROD_URL].includes(
        window.location.hostname,
      )
    ) {
      window.document.title = PIVOTEL_TITLE;
      updateManifestFile('./pulsar-manifest.json');
    } else if (
      ['onecare.frontm.com', 'onecaresolutions.app', ONECARE_STAGE_URL].includes(
        window.location.hostname,
      )
    ) {
      updateManifestFile('./oneCare-manifest.json');
    } else {
      updateManifestFile();
    }
  }, [window.location.hostname]);
  
  useEffect(() => {
    console.log('Environment:', process.env.REACT_APP_ENV);
  }, []); // Empty dependency array means this runs once on mount
  return (
    <>
      {/* <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={showToast}
                autoHideDuration={6000}
                onClose={() => setShowToast(false)}
                message="App is ready for offline use"
            /> */}
     {<UpdateAppVersionDlg
        open={isSWUpdateAvailable || false}
        handleConfirm={resetSWNotificationStates}
        registrationObj={registrationObj}/>}
      <CookiedDisableDlg/>
      <Provider store={store}>
        <Router history={history}>
          <div className='App-wrapper'>
            <Switch>
              <Route exact path='/login' component={LoginPage}/>
              <Route exact path='/jitsi' component={JitsiCall}/>
              <Route exact path='/status' component={CorporateStatusPage}/>
              <Route exact path='/otp' component={OtpPage}/>
              <Route exact path='/qrcode' component={QRCodePage}/>
              <Route exact path='/qrcode' component={QRCodePage}/>
              <ProtectedRoute
                exact
                path='/login/:destination'
                component={LoginPage}/>
              <ProtectedRoute exact path='/signup' component={SignupView}/>
              <ProtectedRoute
                exact
                path='/voyagervoice'
                component={SignupView}/>
              <ProtectedRoute exact path='/vikand' component={SignupView}/>
              <ProtectedRoute
                exact
                path='/onship'
                component={OnShipSignInView}/>
              <ProtectedRoute
                exact
                path='/onship/signup'
                component={SignupView}/>
              <ProtectedRoute
                exact
                path='/sattrackland'
                component={SattracklandSigninView}/>
              <ProtectedRoute
                exact
                path='/sattrackland/signup'
                component={SignupView}/>
              <ProtectedRoute
                exact
                path='/vikandconnect'
                component={HomeContainer}/>
              <ProtectedRoute
                exact
                path='/pulsar'
                component={HomeContainer}/>
              <ProtectedRoute
                exact
                path='/intelli4desks'
                component={SignupView}/>
              <ProtectedRoute exact path='/thuraya' component={SignupView}/>
              <ProtectedRoute
                exact
                path='/stationsatcom'
                component={SignupView}/>
              <ProtectedRoute
                exact
                path='/stationone'
                component={SignupView}/>
              <ProtectedRoute
                exact
                path='/intelsat'
                component={SignupView}/>
              <ProtectedRoute exact path='/sigma' component={SignupView}/>
              <ProtectedRoute exact path='/onecare' component={SignupView}/>
              <ProtectedRoute
                exact
                path='/resetPassword'
                component={ResetPassword}/>
              <ProtectedRoute
                exact
                path='/:domainName/resetPassword'
                component={ResetPassword}/>
              <ProtectedRoute
                exact
                path='/sendcode'
                component={SendCodeAgain}/>
              <ProtectedRoute
                exact
                path='/resetConfirmPassword'
                component={ResetConfirmPassword}/>
              <ProtectedRoute
                exact
                path='/verify'
                component={UserVerificationView}/>
              <Route
                exact
                path='/messages/:type/:botId'
                component={LinkableMessage}/>
              <Route
                exact
                path='/messages/:type/:botId/r/:message'
                component={LinkableMessage}/>
              <Route
                exact
                path='/messages/:type/:botId/s/:message'
                component={LinkableMessage}/>
              <Route path='/app' component={App}/>
              <Route exact path='/' component={HomeContainer}/>

              <Route path='/*' component={Error404}/>
            </Switch>
            <ToastContainer style={{ zIndex: '999999999' }} />
            <Spinner/>
            <NonConversationalLoader/>
            <ErrorPageContainer/>
          </div>
        </Router>
      </Provider>

    </>
  );
};

export default Main;
